.section-adopt[data-v-cc5de5] {
  z-index: 0;
  background: #d6e8e4;
  padding: 52px 0 10px;
  position: relative;
}

.section-adopt .adopt-title[data-v-cc5de5] {
  text-align: center;
  letter-spacing: -.2px;
  margin-bottom: 30px;
  padding-bottom: 25px;
  font-size: 6.93333vw;
  font-weight: bold;
  line-height: 1.46;
  position: relative;
}

@media screen and (width >= 375px) {
  .section-adopt .adopt-title[data-v-cc5de5] {
    font-size: 26px;
  }
}

.section-adopt .adopt-title[data-v-cc5de5]:after {
  content: "";
  background: url("icon_headline_tail.51b540b8.svg") center / cover repeat-x;
  width: 121px;
  height: 11px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.adopt-slider[data-v-cc5de5] {
  position: relative;
  overflow: hidden;
}

.adopt-slider .adopt-slider-wrap[data-v-cc5de5] {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.adopt-slider .adopt-slider-list[data-v-cc5de5] {
  display: flex;
}

.adopt-slider .adopt-slider-list > li[data-v-cc5de5] {
  margin-right: 25px;
}

.adopt-slider .adopt-slider-list > li .adopt-slider-list-child .slider-item[data-v-cc5de5] {
  background: #fff;
  margin-bottom: 25px;
  box-shadow: 0 0 6px #4b9c8466;
}

.adopt-slider .adopt-slider-list > li .adopt-slider-list-child .slider-item a[data-v-cc5de5] {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 95px;
  padding: 10px;
  display: flex;
}

.adopt-slider .adopt-slider-list > li .adopt-slider-list-child .slider-item a img[data-v-cc5de5] {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=index.65a0137b.css.map */
